import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Obfuscate from 'react-obfuscate'

import SEO from '../components/seo'
import Layout from '../containers/layout'
import HeroSkinny from '../components/hero/HeroSkinny'
import BlockContent from '../components/block-content'

export const query = graphql`
  query DatenschutzPageQuery {

    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title {
          _key
          _type
          en
          de
        }
      description
      keywords
      callToActionActive
      callToActionButtonText
      callToActionText
      mainImage {
        copyright {
              _key
              _type
              photographer {
                _key
                _type
                name
                slug {
                  _key
                  _type
                  current
                }
              }
            }
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      seperatorImage {
        copyright {
              _key
              _type
              photographer {
                _key
                _type
                name
                slug {
                  _key
                  _type
                  current
                }
              }
            }
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }

    menu:  sanityMenu(_id: {regex: "/(drafts.|)menu/"}) {
      id
      menuitems {
        _key
        _type
        name {
          _key
          _type
          de
          en
        }
        url
        menutype
      }
      menuName
    }

    impressum: sanityImpressum(_id: { regex: "/(drafts.|)impressum/" }) {
      id
      contactName
      addressZip
      addressStreet
      addressCity
      email
      tel
      programmingCredit
      programmingLink
      legalNotice {
        _key
        _type
        de
        en
      }
      _rawBody
      title {
        _key
        _type
        de
        en
      }
    }

    datenschutz: sanityDatenschutz(_id: { regex: "/(drafts.|)datenschutz/" }) {
      id
      contactName
      addressZip
      addressStreet
      addressCity
      email
      tel
      title {
        _key
        _type
        de
        en
      }
      _rawBody
      _rawLegalIntro
    }

    testimonials: sanitySampleProject {
      id
      testimonialCards {
        _key
        _type
        title
        mainImage {
          _key
          _type
          caption
          alt
          copyright {
            _key
            _type
            photographer {
              _key
              _type
              name
              slug {
                _key
                _type
                current
              }
            }
          }
        }
      }
    }

    photographers: allSanityPhotographer {
      edges {
        node {
          name
          id
        }
      }
    }

    socialMedia: sanitySocialMedia {
      id
      socialMediaItems {
        _key
        _type
        url
        name
      }
    }

}`

const DatenschutzPage = props => {
  const {data, errors, pageContext} = props

  const site = (data || {}).site
  const menu = (data || {}).menu
  const datenschutz = (data || {}).datenschutz
  const socialMedia = (data || {}).socialMedia

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  if (errors) {
    return (
      <Layout language={pageContext.intl.language} menus={menu} pageContext={pageContext} site={site} callToAction={false} socialMedia={socialMedia} isSubPage >
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout language={pageContext.intl.language} menus={menu} pageContext={pageContext} site={site} callToAction={false} socialMedia={socialMedia} isSubPage >
      <SEO title='Datenschutz' />
      <HeroSkinny fluid={site.mainImage.asset.fluid} />
      <Container>
        <section className='section--impressum' id='impressum'>

          <div className='impressum--wrapper datenschutz--wrapper'>
            {pageContext.intl.language === 'de'
              ? <div>
                <h1>{datenschutz.title.de}</h1>

                <BlockContent blocks={datenschutz._rawLegalIntro.de || []} />

                <div className='contactinfo'>

                  <h3>I. Informationen über uns als Verantwortliche</h3>
                  <p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>
                  <ul>
                    <li>{datenschutz.contactName}</li>
                    <li>{datenschutz.addressStreet}</li>
                    <li>{datenschutz.addressZip} {datenschutz.addressCity}</li>
                    <li>Email: <Obfuscate email={datenschutz.email} /> </li>
                    <li>Tel: <Obfuscate tel={datenschutz.tel} /></li>

                  </ul>
                </div>

                <BlockContent blocks={datenschutz._rawBody.de || []} />
              </div>
              : <div>
                <h1>{datenschutz.title.de}</h1>
              </div>
            }
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export default DatenschutzPage
